import React from "react"
import {Row, Col, Container, Button} from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {FaCalendarPlus, FaCocktail, FaHandPaper, FaHandRock, FaHeart, FaIdBadge, FaInfo, FaPeace} from "react-icons/fa";

const IndexPage = () => (
    <Layout pageInfo={{pageName: "index"}}>
        <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]}/>
        <Container>
            <Row>
                <Col lg={true}>
                    <div className="text-center header titlecard">
                        <h1>Massages by Jooley</h1>
                        <p>

                            Licensed Massage Therapist
                        </p>
                    </div>

                </Col>
            </Row>
        </Container>

        <Container>
            <Row>
                <Col lg={true}>
                    <FaHeart/> Hello clients!
                    <p>
                        Serving Portland, OR and surrounding areas, Jooley travels to <strong>YOU</strong>!

                        <br/><br/>
                        <FaInfo/> <strong>Covid-19 Regulations:</strong> Temperature taken before appointment, please keep mask on
                        throughout appointment. Sanitizing
                        procedures followed to ensure your safety and the safety of fellow clients.  Jooley
                        is committed to providing a safe and comfortable environment.

                        <br/><br/>
                        <FaInfo/> A $20 setup fee will be applied for the setup of a mobile massage table.

                        <br/><br/>
                        Included in the mobile set up
                        is your choice of massage table or chair and the full ambiance of soothing music
                        with a professional and sanitary practice.

                        <p>
                            <br/>Jooley provides the following services:
                            <ul>
                                <li><strong>Onsite Massage Therapy: </strong>Bring the convenience of Massage
                                    Therapy to your
                                    place of work or special event!
                                </li>
                                <li><strong>House Appointments: </strong>
                                    Where you can enjoy Massages in the comfort of your own home!
                                </li>
                                <li><strong>Qi Gong Instruction: </strong>
                                    Where you can learn this practice for similar benefits as massage.
                                </li>
                            </ul>
                        </p>

                        <a href="mailto:massagesbyjooley@gmail.com"
                           target="_blank"
                           rel="noopener noreferrer"><FaCalendarPlus/> Book Now!</a>
                    </p>
                    <br/>
                    <p>
                        <strong>Massages by Jooley, LLC provides peace of mind. <FaPeace/></strong>
                    </p>
                </Col>
                <Col lg={true}>

                    Licensed Massage Therapist <FaIdBadge/> # 19979<br/>

                    <div className="jooles1"> </div><br/>


                </Col>
            </Row>
        </Container>

        <Container>

            <Row>
                <Col lg={true}>

                    <div class="text-center border">
                        <h1>
                            <FaHandPaper/>
                        </h1>
                        <h3>
                            Swedish Massage
                        </h3>

                        <p>
                            ​<strong>For relaxation</strong><br/>
                            ​Slow, calming techniques are used to manipulate the soft tissues,
                            promoting blood flow and healing to take place.
                        </p>
                        <p>
                            <a href="mailto:massagesbyjooley@gmail.com"
                               target="_blank"
                               rel="noopener noreferrer">
                                <Button variant="primary" size="lg">
                                    <FaCalendarPlus/><br/>
                                    Book Now!</Button>
                            </a>
                        </p>
                    </div>
                </Col>
                <Col lg={true}>
                    <div className="text-center border">
                        <h1>
                            <FaHandRock/><br/>
                        </h1>
                        <h3>Deep Tissue Massage</h3>

                        <p>
                            ​<strong>Firm Pressure</strong><br/>
                            Essential to affect the sub-layer muscles and fascia,
                            you will gain range of motion and relief from pain.
                        </p>
                        <p>
                            <a href="mailto:massagesbyjooley@gmail.com"
                               target="_blank"
                               rel="noopener noreferrer">
                                <Button variant="primary" size="lg">
                                    <FaCalendarPlus/><br/>
                                    Book Now!</Button>
                            </a>
                        </p>
                    </div>
                </Col>
                <Col lg={true}>
                    <div className="text-center border">
                        <h1>
                            <FaCocktail/><br/>
                        </h1>
                        <h3>Lomi Lomi</h3>

                        <p>
                            <iframe width="280" height="157" src="https://www.youtube.com/embed/KKw4tcSdY5w"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </p>

                        <p>
                            ​<strong>​Hawaiian massage</strong><br/>
                            Using forearms and long strokes, you feel the rhythm of the ocean,
                            which allows your body to disengage from the mind.
                        </p>
                        <p>
                            <a href="mailto:massagesbyjooley@gmail.com"
                               target="_blank"
                               rel="noopener noreferrer">
                                <Button variant="primary" size="lg">
                                    <FaCalendarPlus/><br/>
                                    Book Now!</Button>
                            </a>
                        </p>
                    </div>
                </Col>
            </Row><br/>


        </Container>
    </Layout>
)

export default IndexPage
